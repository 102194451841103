<template>
  <div class="col-md-12 mx-auto">
        <div class="card">
            <div id="report" class="card-body">
                <div class="container-fluid">
                  <div style="width: 80px;">
                    <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                  </div>
                  <div class="headerreport">
                    <div class="row">
                      {{ companydata.cmp_nmbre }}
                    </div>
                    <div class="row">
                      {{ fecha }}
                    </div>
                  </div>
                </div>
                <br>
                <div class="card-title">
                  <h1 class="titlereport">Estadística de Documentos tramitados</h1>
                  <h5 class="subtitle">{{ rango }}</h5>
                </div>
                <div v-if="showProgress" style="text-align:center">
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div id="wrapping" class="col-md-10 table-responsive m-t-40 bodyreport">
                    <table id="reporteEstadisticDocsTramitados" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                              <th>Dependencia</th>
                              <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" class="rowstyle">
                              <td>{{ item.dep_tramite_nmbre }}</td>
                              <td class="text-right">{{ item.num }}</td>
                            </tr>
                        </tbody>
                        <tr style="font-weight:bold">
                            <td>TOTAL</td>
                            <td class="text-right">{{ suma }}</td>
                        </tr>
                    </table>
                </div>
                <div class="row justify-content-center">
                  <div id="chartContainer" class="col-sm-6">
                    <bar-chart :chart-id="'myChart'" :chart-data="datacollection" :options="options"></bar-chart>
                  </div>
                </div>
                <!--
                <div>
                  <canvas responsive="true" id="myChart" width="400" height="400"></canvas>
                </div>
                -->
            </div>
        </div>
        <br>
        <div class="row justify-content-around" align="center">
          <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
          <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
        </div>
    </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import print from 'print-js';
import XLSX from 'xlsx';
import BarChart from './BarChart.js';

//import Chart from 'chart.js';

export default {
  components: { 
    //VProgressCircular,
    BarChart 
    },
  data(){
    return{
      message: '',
      items: [],
      dependencias: [],
      debug: null,
      fecha: null,
      rango: '',
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      showProgress: false,
      datacollection: {},
      options: {},
      viewButton: '',
      suma: 0
    }
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchDependencia((err) => {
        if (err){
            alert('Error al consultar las dependencias.');
        } else {
            this.debug = this.$route.params;
            this.dcm_fcharadini = this.$route.params.query.dcm_fcharadini;
            this.dcm_fcharadfin = this.$route.params.query.dcm_fcharadfin;

            this.rango = "Desde: " + moment(this.dcm_fcharadini).format('DD/MMM/YYYY') + " --- Hasta: " + moment(this.dcm_fcharadfin).format('DD/MMM/YYYY');
            this.searchItems(this.$route.params.query);
        }
    });
  },
  mounted: function() {
  },
  methods: {
    fillData () {
      var labels = this.items.map(i => (i.dep_tramite_nmbre));
      var data = this.items.map(i => (i.num));

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad',
            data: data,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }
        ]
      };

      this.options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cantidad'
                }  
            }]
        },
        responsive: true,
        title: {
          display: true,
          text: 'Número de documentos tramitados'
        },
        legend: {
					display: false
				},
        tooltips: {
          mode: 'index',
          intersect: true
        },
        annotation: {
          annotations: [{
            type: 'line',
            mode: 'vertical',
            scaleID: 'y-axis-0',
            value: 5,
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 4,
            label: {
              enabled: false,
              content: 'Test label'
            }
          }]
        }
      };
    },
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = String(this.$store.state.company);
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/estad-docs-tramitados';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          this.suma = 0;
          for (var i = 0; i < list.length; i++){
            for (var j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_tramite_id == this.dependencias[j]._id){
                list[i].dep_tramite_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }
            this.suma += list[i].num;

          }

          this.items = list;
          this.fillData();

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Print');
      p.document.open();
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      var chartContainer = p.document.getElementById('chartContainer');
      var myChart = document.getElementById('myChart');
      p.document.getElementById('myChart').remove();
      const image = p.document.createElement('img');
      image.src = myChart.toDataURL();
      chartContainer.appendChild(image);
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus()
              p.print();
              p.close();
      }, 1000);        
    },
    exportReport(){
      var tableId = 'reporteEstadisticDocsTramitados';

      var report = [];
      var suma = 0;
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.dep_tramite_nmbre = this.items[i].dep_tramite_nmbre;
        item.num = this.items[i].num;
        suma += this.items[i].num;
        
        report.push(item);
      }
      
      let item = {};
      item.dep_tramite_nmbre = 'TOTAL';
      item.num = suma;
      report.push(item);

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Estadística de Documentos tramitados"],
        [this.rango],
        [],
        ["Dependencia", "Cantidad"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

  .small {
      width: 500px;
      margin: 50px auto;
    }  
</style>
